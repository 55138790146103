
.filter-main-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .coloumns{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        width: 75%;
    }
}

.dropdowns{
    width: 100%;
    margin-bottom: 15px !important;
 }

 .custom-checkbox {

    position: relative;
    top: 5px;
    margin-right: 0.5rem;
    cursor: pointer;
     .checkmark {
       width: 21px;
       height: 21px;
       border: 2px solid #00B0A0;
       display: inline-block;
       border-radius: 3px;
       background: #00B0A0 url('../../../assets/images/white_check.png') center/1250% no-repeat;
    }
     input{
       display: none;
    }

    input:checked + .checkmark{
        background-size: 60%;
    }

}
  
