.mandatory-field{
    color: red;
    font-size:18px;
}
.deadline{
    border: 1px solid #b4b4b4;
    padding: 5px 30px;
    border-radius: 4px;
}
.gray-text{
    color: gray;
}
.dashed-a{
    display: block;
    width: 100%;
    text-align: center;
    border: dashed;
    border-radius: 4px;
    padding: 8px;
    font-weight: 900;
    color: #79e0d4;
}
.custom-border{
    border: none !important;
    background-color: #d1f4f0 !important;
    .p-button-label{
        padding: 4px;
        font-weight: 700;
    }
    &:focus {
        box-shadow: none;
        color: #2eaea0 !important;
    }
}
.dashed-border{
    border: dashed !important;
    color: #2eaea0 !important;
    .p-button-label{
        padding: 4px;
        font-weight: 700;
    }
    &:focus {
        box-shadow: none;
        color: #2eaea0 !important;
    }
}
.d-flex{
    display: flex;
}
@media (max-width: 986px) {
    .d-flex {
       flex-direction: column;
    }
  }
.list-mainDiv{
    max-height: 90vh;
    overflow: auto;
}

.p-disabled{
    color: black;
}

.badge-position{
    position: relative;
    top: 14px;
}
.bg-lightGray{
    &:active{
        box-shadow: 0px 9px 11px grey;
    } &:focus{
        box-shadow: 0px 9px 11px grey;
    }
}

.pool-mainDiv{
    max-height: 45vh;
    overflow: auto;
}

.openPool{
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    margin-bottom: 0 !important;
}