$gutter: 1rem;

@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

.mazhrBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 7px;
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: white;
}
.default-pink{
    background-color: #00B0A0;;
}